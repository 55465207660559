import React from "react";
import { HashRouter, Routes, Route } from 'react-router-dom';
import NoPageFound from "./nopagefound";
import Home from "./home";
import Header from "./HeaderFooter/header";
import ServicesProvided from "./services/services-provided";
import ContactUs from "./Contact/contact-us";

class MainComponent extends React.Component {
    render(){
        return(
            <div className="maincomponent container-fluid">
                <div>
                    <HashRouter>
                        <Header/>
                        <div className="row">
                            <div className="col-0"/>
                            <div className="col-12">
                                <Routes>
                                    <Route exact path="/" element={<Home />} />
                                    <Route exact path="/app/index.html" element={<Home />} />
                                    <Route exact path="home" element={<Home />} />
                                    <Route exact path="services" element={<ServicesProvided />} />
                                    <Route exact path="contactus" element={<ContactUs />} />
                                    <Route exact path="*" element={<NoPageFound />} />
                                </Routes>
                            </div>
                            <div className="col-0"/>
                        </div>
                    </HashRouter>
                </div>
            </div>
        )
    }
}

export default MainComponent;