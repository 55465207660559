import React from "react";
import jiya_tech_website_img from '../public/img/JIYA_Website.png';

function Home() {
    return(
        <div style={{backgroundColor : ''}}>
            <div className="row">
                <div className="col-lg-12 col-12 p-0">
                    <img src={jiya_tech_website_img} width="100%" alt="JIYA Technologies"/>
                </div>
                <div className="col-lg-0"/>
                {/*<div className="col-lg-5 col-12 text-end" ><br/>
                    <div className="h1 text-decoration-underline">Services</div>
                    <ul className="h3 fw-bold fs-1" style={{listStyleType : 'none', fontFamily : 'fantacy', color : 'darkblue'}}>
                        <li>Billing Application</li>
                        <li>Maintenance Application</li>
                        <li>Website Design</li>
                        <li>Logo Design</li>
                        <li>Visiting Card Design</li>
                        <li>Cloud Based Hosting</li>
                        <li>Digital Marketing</li>
                        <li>Domain Purchase & Maintenance</li>
                        <li>SEO</li>
                    </ul>
                </div>*/}
            </div>
        </div>
    )
}

export default Home;