import React from "react";

function ServicesProvided() {
    return(
        <div style={{backgroundColor : 'pink'}}>
            <br/><br/><br/><br/><br/>
            <h1><center>Services Provided</center></h1>
        </div>
    )
}

export default ServicesProvided;