import React, { useState, useEffect } from "react";
import { Link, useLocation} from 'react-router-dom';
import { FiMenu, FiX } from 'react-icons/fi';
import './navigationbar.css';
import $ from "jquery";

function Header(){
    
    const location = useLocation();
    const { hash, pathname, search } = location;
    //const [defaultmenu, setDefaultMenu] = useState("");
    //const defaultmenu = "/home";
    //console.log("Hash : " + hash + ", Path : " + pathname + ", Search : " + search);
    /*const menus = [
        {
            key : "/home",
            url : "home",
            title : "Home"
        },
        {
            key : "/contactus",
            url : "contactus",
            title : "Contact Us"
        }
    ]*/
    const menus = [
            {
                key : "/home",
                url : "home",
                title : "Home"
            }
        ]
    const [menusDisplay, setMenusDisplay] = useState(false);
    const [activeMenu, setActiveMenu] = useState(pathname);
    const handleActiveMenu = (activeMenu) => setActiveMenu(activeMenu);
    const handleMenusDisplay = () => setMenusDisplay(!menusDisplay);
    const closeMenu = () => {
        setMenusDisplay(false);
        //handleActiveMenu(activeMenu);
    }

    return(
        <div>
            {/*<nav className="navbar navbar-expand-lg bg-light">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <a className="navbar-brand" href="#home">JIYA Technologies</a>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <span className="collapse navbar-collapse" id="navbarNav">
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <a className="nav-link active" aria-current="page" href="#saleorder">Services</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#addnewproduct">Add New Product</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#addnewcustomer">Add New Customer</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#purchaseorder">Purchase Order</a>
                                    </li>
                                </ul>
                            </span>
                        </div>
                    </div>
                </div>
        </nav>*/}
            {/*<div className="row" style={{backgroundColor : 'black'}}>
                <div className="col-12"><br/>
                    <Link to={"home"} style={{textDecoration : 'none'}}><h3 style={{color : 'orange'}}>JIYA Technologies</h3></Link>
                </div>
            </div>*/}
            <div className="row" style={{backgroundColor : 'black'}}>
                <div className="col-12">
                    <nav className="navigation-bar">
                        <div className="navigation-icons" onClick={handleMenusDisplay}>
                            {menusDisplay ? <FiX style={{color: 'white'}}/> : <FiMenu style={{color: 'white'}}/>}
                        </div>
                        <ul className={menusDisplay ? "navigation-links active" : "navigation-links"}>
                            {menus.map((menu, index) => {
                                return(
                                    (pathname === "/" && menu.title === "Home" || pathname === menu.key) ? 
                                    <li><span className={"navigation-item active"}>{menu.title}</span></li>
                                    :
                                    <li><a href={"#" + menu.url} onClick={closeMenu} className="navigation-item">{menu.title}</a></li>
                                )
                            })}
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    )
}

export default Header;