import React, { useState } from "react";
import './contact-us.css';

function ContactUs() {
    
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [mail, setMail] = useState("");
    const [mocode, setMocode] = useState("");
    const [monumber, setMonumber] = useState("");
    const [wacode, setWacode] = useState("");
    const [wanumber, setWanumber] = useState("");
    const [qry, setQry] = useState("");
    const [responseTxt, setResponseTxt] = useState("");
    const [resTextColor, setResTextColor] = useState("");
    const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);

    const moCodeOnChange = (event) => {
        setMocode(event.target.value);
        setWacode(event.target.value);
    }

    const moNumberOnChange = (event) => {
        setMonumber(event.target.value.substring(0,15));
        setWanumber(event.target.value.substring(0,15));
    }

    const resetForm = (clearResponse) => {
        setFname("");
        setLname("");
        setMail("");
        setMocode("");
        setMonumber("");
        setWacode("");
        setWanumber("");
        setQry("");
        if(clearResponse){
            setResponseTxt("");
            setResTextColor("");
        }
    }

    const addQuery = (event) => {
        event.preventDefault();
        const data = {
            fname : fname,
            lname : lname,
            mail : mail,
            mocode : mocode,
            monumber : monumber,
            wacode : wacode,
            wanumber : wanumber,
            qry : qry
        };
        const payload = {
            action : "contactusinsert",
            data : data,
        };
        const method = "POST";
        const url = "/dbaccessapi";
        const headers = new Headers({
            "ZCFKEY" : "09d31e40afae67225a8c08281b643b1c"
        });
        setResponseTxt("");
        setResTextColor("");
        setSubmitBtnDisabled(true);
        return fetch(url, {
            method : method,
            body : JSON.stringify(payload),
            headers : headers
        }).then(res => {
            //console.log(res);
            if(res.status === 401){
                res.json().then((result) =>{
                    if(result.output){
                        alert(result.response_string);
                    } else{
                        alert("Unauthorized");
                    }
                    //window.open("/__catalyst/auth/login", "_self");
                });
            }
            else if(res.status !== 200){
                res.json().then((result) =>{
                    setResponseTxt(result.output);
                    setResTextColor("text-danger");
                });
            }
            else{
                res.json().then((result) =>{
                    //console.log(result);
                    setResponseTxt(result.output);
                    setResTextColor("text-success");
                    resetForm(false);
                });
            }
            setSubmitBtnDisabled(false);
        }).catch((error) => {
            console.log(error);
            setResponseTxt(error);
            setResTextColor("text-danger");
            setSubmitBtnDisabled(false);
        });
    }

    return(
        <div>
            <div className="row pt-lg-5 pt-2">
                <div className="col-lg-4"/>
                <div className="col-lg-4 col-12 pt-2" style={{backgroundColor : 'white', border: '1px ridge', borderRadius: '10px'}}>
                    <div className="row">
                        <div className="col-12">
                            <span className={" " + resTextColor}>{responseTxt}</span>
                        </div>
                    </div><br/>
                    <form className="form" onSubmit={event => addQuery(event)}>
                        <div className="row">
                            <div className="col-12">
                                <label className="form-label">First Name <font color="red">*</font></label>
                                <input type="text" className="form-control" value={fname} onChange={(event) => setFname(event.target.value)} maxLength={45} required/>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12">
                                <label className="form-label">Last Name <font color="red">*</font></label>
                                <input type="text" className="form-control" value={lname} onChange={(event) => setLname(event.target.value)} maxLength={45} required/>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12">
                                <label className="form-label">Email <font color="red">*</font></label>
                                <input type="email" className="form-control" value={mail} onChange={(event) => setMail(event.target.value)} maxLength={35} required/>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12">
                                <label className="form-label">Mobile Number <font color="red">*</font></label>
                                <div className="row">
                                    <div className="col-1 text-end pt-1 fw-bold">+</div>
                                    <div className="col-2 p-0">
                                        <input type="number" className="form-control" placeholder="Code" value={mocode} onChange={(event) => moCodeOnChange(event)} required/>
                                    </div>
                                    <div className="col-9">
                                        <input type="number" className="form-control" placeholder="Mobile Number" value={monumber} onChange={(event) => moNumberOnChange(event)} required/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12">
                                <label className="form-label">Whatsapp Number</label>
                                <div className="row">
                                    <div className="col-1 text-end pt-1 fw-bold">+</div>
                                    <div className="col-2 p-0">
                                        <input type="number" className="form-control" placeholder="Code" value={wacode} onChange={(event) => setWacode(event.target.value)}/>
                                    </div>
                                    <div className="col-9">
                                        <input type="number" className="form-control" placeholder="Whatsapp Number" value={wanumber} onChange={(event) => setWanumber(event.target.value.substring(0,15))} maxLength={15}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12">
                                <label className="form-label">Query <font color="red">*</font> ({qry.length}/240)</label>
                                <textarea className="form-control" placeholder="Enter your query..." maxLength={240} value={qry} onChange={(event) => setQry(event.target.value)} required/>
                            </div>
                        </div>
                        <div className="row pt-3">
                            <div className="col-6">
                                <input type="submit" className="btn btn-outline-primary fullsizedbutton" value="Submit" style={{width : '-webkit-fill-available'}} disabled={submitBtnDisabled}/>
                            </div>
                            <div className="col-6">
                                <input type="reset" className="btn btn-outline-secondary fullsizedbutton" value="Clear" style={{width : '-webkit-fill-available'}} onClick={() => resetForm(true)}  disabled={submitBtnDisabled}/>
                            </div>
                        </div><br/>
                    </form>
                </div>
                <div className="col-lg-4"/>
            </div>
        </div>
    )
}

export default ContactUs;