import React from 'react'

function NoPageFound() {
  return (
    <div className='nopagefound'><br/><br/>
      <center><h1 className="text-danger fw-bold">404 <br/>Sorry. No Page Found for the URL entered.</h1></center>
    </div>
  )
}

export default NoPageFound
